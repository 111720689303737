import { i18n } from '@/i18n';
import { TableCategory, TableHeaders } from '@/shared/components/table/models';
import { boolToYN } from '@/utils/boolToYN';

export const Categories1TableHeaders: TableHeaders[] = [
  { key: 'number', label: i18n.tc('mainTable.no'), tdClass: 'min-w-50' },
  {
    key: 'category',
    label: i18n.tc('reports.categories')
  },
  {
    key: 'date',
    label: i18n.tc('common.date')
  },
  {
    key: 'orderNumber',
    label: i18n.tc('mainTable.wo')
  },
  {
    key: 'billTo',
    label: i18n.tc('order.billTo')
  }
];

export const EquipmentTableHeaders: TableHeaders[] = [
  {
    key: 'containerNumber',
    label: i18n.tc('order.cont')
  },
  {
    key: 'shippingLine',
    rendererKey: 'equipment',
    label: i18n.tc('order.ssl'),
    collapsed: true
  },
  {
    key: 'containerSize',
    rendererKey: 'equipment',
    label: i18n.tc('mainTable.sz'),
    collapsed: true
  },
  {
    key: 'chassisNumber',
    rendererKey: 'equipment',
    label: i18n.tc('order.chassisNumber'),
    collapsed: true
  },
  {
    key: 'chassisPool',
    rendererKey: 'equipment',
    label: i18n.tc('order.chassisPool'),
    collapsed: true
  },
  {
    key: 'bondEntry',
    rendererKey: 'equipment',
    label: i18n.tc('mainTable.bond'),
    collapsed: true
  }
];

export const InfoTableHeaders: TableHeaders[] = [
  {
    key: 'referenceNumber',
    label: i18n.tc('mainTable.ref')
  },
  {
    key: 'poNumber',
    rendererKey: 'info',
    label: i18n.tc('mainTable.po'),
    collapsed: true
  },
  {
    key: 'weight',
    rendererKey: 'info',
    label: i18n.tc('order.weight'),
    collapsed: true
  },
  {
    key: 'quantity',
    rendererKey: 'info',
    label: i18n.tc('order.quantity'),
    collapsed: true
  },
  {
    key: 'overHeight',
    rendererKey: 'info',
    label: i18n.tc('order.oh'),
    collapsed: true,
    formatter: boolToYN
  },
  {
    key: 'overWeight',
    rendererKey: 'info',
    label: i18n.tc('order.ow'),
    collapsed: true,
    formatter: boolToYN
  },
  {
    key: 'haz',
    rendererKey: 'info',
    label: i18n.tc('order.haz'),
    collapsed: true,
    formatter: boolToYN
  },
  {
    key: 'hot',
    rendererKey: 'info',
    label: i18n.tc('order.hot'),
    collapsed: true,
    formatter: boolToYN
  },
  {
    key: 'oth',
    rendererKey: 'info',
    label: i18n.tc('order.hot'),
    collapsed: true
  }
];

export const BookingTableHeaders: TableHeaders[] = [
  {
    key: 'bookingNumber',
    label: i18n.tc('mainTable.booking')
  },
  {
    key: 'streetTurn',
    rendererKey: 'booking',
    label: i18n.tc('order.st'),
    collapsed: true
  },
  {
    key: 'erd',
    rendererKey: 'booking',
    label: i18n.tc('order.erd'),
    collapsed: true
  },
  {
    key: 'cutOff',
    rendererKey: 'booking',
    label: i18n.tc('mainTable.co'),
    collapsed: true
  }
];

export const Categories2TableHeaders: TableHeaders[] = [
  {
    key: 'returnFreeDay',
    label: i18n.tc('order.rfd')
  }
];

export const POutTableHeaders: TableHeaders[] = [
  {
    key: 'pulloutLocation',
    label: i18n.tc('mainTable.pOut'),
    tdClass: 'min-w-130'
  },
  {
    key: 'pulloutSchedule',
    rendererKey: 'pOut',
    label: i18n.tc('mainTable.scheIn'),
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'pulloutActualIn',
    rendererKey: 'pOut',
    label: i18n.tc('mainTable.actualIn'),
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'pulloutActualOut',
    rendererKey: 'pOut',
    label: i18n.tc('mainTable.actualOut'),
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'pulloutDriver',
    rendererKey: 'pOut',
    label: i18n.tc('mainTable.drv'),
    collapsed: true,
    tdClass: 'min-w-130'
  }
];

export const DilvTableHeaders: TableHeaders[] = [
  {
    key: 'deliveryLocation',
    label: i18n.tc('mainTable.dilv'),
    tdClass: 'min-w-130'
  },
  {
    key: 'deliverySchedule',
    rendererKey: 'dilv',
    label: i18n.tc('mainTable.scheIn'),
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'deliveryActualIn',
    rendererKey: 'dilv',
    label: i18n.tc('mainTable.actualIn'),
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'deliveryActualOut',
    rendererKey: 'dilv',
    label: i18n.tc('mainTable.actualOut'),
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'deliveryDriver',
    rendererKey: 'dilv',
    label: i18n.tc('mainTable.drv'),
    collapsed: true,
    tdClass: 'min-w-130'
  }
];

export const PUTableHeaders: TableHeaders[] = [
  {
    key: 'pickupLocation',
    label: i18n.tc('mainFilters.pickUp'),
    tdClass: 'min-w-130'
  },
  {
    key: 'loadConfirmation',
    rendererKey: 'pU',
    label: i18n.tc('order.loadCFM'),
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'pickupSchedule',
    rendererKey: 'pU',
    label: i18n.tc('mainTable.scheIn'),
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'pickupActualIn',
    rendererKey: 'pU',
    label: i18n.tc('mainTable.actualIn'),
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'pickupActualOut',
    rendererKey: 'pU',
    label: i18n.tc('mainTable.actualOut'),
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'pickupDriver',
    rendererKey: 'pU',
    label: i18n.tc('mainTable.drv'),
    collapsed: true,
    tdClass: 'min-w-130'
  }
];

export const RtnTableHeaders: TableHeaders[] = [
  {
    key: 'returnLocation',
    label: i18n.tc('mainTable.rtn'),
    tdClass: 'min-w-130'
  },
  {
    key: 'returnSchedule',
    rendererKey: 'rtn',
    label: i18n.tc('mainTable.scheIn'),
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'returnActualIn',
    rendererKey: 'rtn',
    label: i18n.tc('mainTable.actualIn'),
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'returnActualOut',
    rendererKey: 'rtn',
    label: i18n.tc('mainTable.actualOut'),
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'returnDriver',
    rendererKey: 'rtn',
    label: i18n.tc('mainTable.drv'),
    collapsed: true,
    tdClass: 'min-w-130'
  }
];

export const ArTableHeaders: TableHeaders[] = [
  {
    key: 'ar',
    label: i18n.tc('mainTable.ar')
  },
  {
    key: 'arTruckingCharges',
    rendererKey: 'ar',
    label: i18n.tc('mainTable.trucking'),
    collapsed: true
  },
  {
    key: 'arFuelSurCharges',
    rendererKey: 'ar',
    label: i18n.tc('mainTable.fscR'),
    collapsed: true
  },
  {
    key: 'arChassis',
    rendererKey: 'ar',
    label: i18n.tc('mainTable.chR'),
    collapsed: true
  },
  {
    key: 'arPerdiem',
    rendererKey: 'ar',
    label: i18n.tc('mainTable.peR'),
    collapsed: true
  },
  {
    key: 'arDemurrage',
    rendererKey: 'ar',
    label: i18n.tc('mainTable.deR'),
    collapsed: true
  },
  {
    key: 'arDryRun',
    rendererKey: 'ar',
    label: i18n.tc('mainTable.drR'),
    collapsed: true
  }
];

export const ApTableHeaders: TableHeaders[] = [
  {
    key: 'apPerdiem',
    label: i18n.tc('mainTable.ap')
  },
  {
    key: 'apDryRun',
    rendererKey: 'ap',
    label: i18n.tc('mainTable.drvP'),
    collapsed: true
  },
  {
    key: 'apChassisFees',
    rendererKey: 'ap',
    label: i18n.tc('mainTable.chP'),
    collapsed: true
  },
  {
    key: 'apPerdiem1',
    rendererKey: 'ap',
    label: i18n.tc('mainTable.peP'),
    collapsed: true
  },
  {
    key: 'apDemurrage',
    rendererKey: 'ap',
    label: i18n.tc('mainTable.deP'),
    collapsed: true
  },
  {
    key: 'apDriverPay',
    rendererKey: 'ap',
    label: i18n.tc('mainTable.drP'),
    collapsed: true
  }
];

export const Categories3TableHeaders: TableHeaders[] = [
  {
    key: 'profit',
    label: i18n.tc('mainTable.prft')
  },
  {
    key: 'inv',
    label: i18n.tc('mainTable.inv')
  }
];

export const DocumentTableHeaders: TableHeaders[] = [
  {
    key: 'filePod',
    label: i18n.tc('mainTable.pod'),
    formatter: boolToYN
  },
  {
    key: 'fileBl',
    rendererKey: 'document',
    label: i18n.tc('mainTable.bl'),
    collapsed: true,
    formatter: boolToYN
  },
  {
    key: 'fileDo',
    rendererKey: 'document',
    label: i18n.tc('mainTable.do'),
    collapsed: true,
    formatter: boolToYN
  },
  {
    key: 'fileIt',
    rendererKey: 'document',
    label: i18n.tc('mainTable.it'),
    collapsed: true,
    formatter: boolToYN
  }
];

export const Categories4TableHeaders: TableHeaders[] = [
  {
    key: 'remarks',
    label: i18n.tc('order.remarks')
  }
];

export const ExportRegularCategoriesHeaders: TableCategory[] = [
  {
    key: 'category1',
    label: ' ',
    maxColspan: Categories1TableHeaders.length,
    minColspan: 1
  },
  {
    key: 'equipment',
    label: i18n.tc('mainTable.headers.equipment'),
    class: 'equipment-color',
    maxColspan: EquipmentTableHeaders.length,
    minColspan: 1,
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'info',
    label: i18n.tc('mainTable.headers.info'),
    class: 'info-color',
    maxColspan: InfoTableHeaders.length,
    minColspan: 1,
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'booking',
    label: i18n.tc('mainTable.booking'),
    class: 'booking-color',
    maxColspan: BookingTableHeaders.length,
    minColspan: 1,
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'category2',
    label: ' ',
    maxColspan: Categories2TableHeaders.length,
    minColspan: 1
  },
  {
    key: 'pOut',
    label: i18n.tc('mainTable.headers.pOutD'),
    class: 'p-out-color',
    collapsed: true,
    opportunityСhange: true,
    maxColspan: POutTableHeaders.length,
    minColspan: 1
  },
  {
    key: 'dilv',
    label: i18n.tc('mainTable.headers.dilvD'),
    maxColspan: DilvTableHeaders.length,
    minColspan: 1,
    class: 'dilv-color',
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'pU',
    label: i18n.tc('mainTable.headers.puD'),
    maxColspan: PUTableHeaders.length,
    minColspan: 1,
    class: 'p-u-color',
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'rtn',
    label: i18n.tc('mainTable.headers.rtnD'),
    maxColspan: RtnTableHeaders.length,
    minColspan: 1,
    class: 'rtn-color',
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'ar',
    label: i18n.tc('mainTable.headers.ar'),
    maxColspan: ArTableHeaders.length,
    minColspan: 1,
    class: 'ar-color',
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'ap',
    label: i18n.tc('mainTable.headers.ap'),
    maxColspan: ApTableHeaders.length,
    minColspan: 1,
    class: 'ap-color',
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'category3',
    label: '',
    maxColspan: Categories3TableHeaders.length,
    minColspan: 1
  },
  {
    key: 'document',
    label: i18n.tc('mainTable.headers.doc'),
    maxColspan: DocumentTableHeaders.length,
    minColspan: 1,
    class: 'document-color',
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'category4',
    label: '',
    maxColspan: Categories4TableHeaders.length,
    minColspan: 1
  }
];

export const ExportRegularTableHeaders: TableHeaders[] = [
  ...Categories1TableHeaders,
  ...EquipmentTableHeaders,
  ...InfoTableHeaders,
  ...BookingTableHeaders,
  ...Categories2TableHeaders,
  ...POutTableHeaders,
  ...DilvTableHeaders,
  ...PUTableHeaders,
  ...RtnTableHeaders,
  ...ArTableHeaders,
  ...ApTableHeaders,
  ...Categories3TableHeaders,
  ...DocumentTableHeaders,
  ...Categories4TableHeaders
];
