import { i18n } from '@/i18n';

export enum PropertyType {
  TrailNumber = 'trailNumber',
  OrderNumber = 'orderNumber',
  ContainerNumber = 'containerNumber',
  ReferenceNumber = 'referenceNumber',
  ChassisNumber = 'chassisNumber',
  PONumber = 'poNumber',
  MasterBillOfLading = 'masterBillOfLading',
  BookingNumber = 'bookingNumber',
  BillTo = 'billTo',
  PulloutLocation = 'pulloutLocation',
  PickupLocation = 'pickupLocation',
  DeliveryLocation = 'deliveryLocation',
  ReturnLocation = 'returnLocation',
  Driver = 'driver'
}

export enum MainOrderType {
  ImportRegular = 'IMP-REG',
  ExportRegular = 'EXP-REG',
  VanRegular = 'VAN',
  CyToCy = 'CY-TO-CY',
  BareChassis = 'BARE-CHASSIS',

  // TODO update after API reports wil be done
  IMP = 'IMP',
  EXP = 'EXP',
  CTY = 'CTY',
  CHS = 'CHS'
}

export const allPropertyTypes = [
  {
    value: PropertyType.ContainerNumber,
    text: i18n.tc('mainFilters.containerHash')
  },
  { value: PropertyType.OrderNumber, text: i18n.tc('mainFilters.wo') },
  { value: PropertyType.ReferenceNumber, text: i18n.tc('mainFilters.ref') },
  { value: PropertyType.PONumber, text: i18n.tc('mainFilters.po') },
  { value: PropertyType.MasterBillOfLading, text: i18n.tc('mainFilters.mbl') },
  { value: PropertyType.BillTo, text: i18n.tc('mainFilters.billTo') },
  { value: PropertyType.PickupLocation, text: i18n.tc('mainFilters.pickUp') },
  {
    value: PropertyType.DeliveryLocation,
    text: i18n.tc('mainFilters.delivery')
  },
  { value: PropertyType.ReturnLocation, text: i18n.tc('mainFilters.return') },
  { value: PropertyType.Driver, text: i18n.tc('mainFilters.driverId') }
];

export const exportPropertyTypes = [
  {
    value: PropertyType.ContainerNumber,
    text: i18n.tc('mainFilters.containerHash')
  },
  { value: PropertyType.OrderNumber, text: i18n.tc('mainFilters.wo') },
  { value: PropertyType.ReferenceNumber, text: i18n.tc('mainFilters.ref') },
  { value: PropertyType.PONumber, text: i18n.tc('mainFilters.po') },
  { value: PropertyType.BookingNumber, text: i18n.tc('mainFilters.po') },
  { value: PropertyType.BillTo, text: i18n.tc('mainFilters.billTo') },
  { value: PropertyType.PickupLocation, text: i18n.tc('mainFilters.pickUp') },
  {
    value: PropertyType.DeliveryLocation,
    text: i18n.tc('mainFilters.delivery')
  },
  { value: PropertyType.ReturnLocation, text: i18n.tc('mainFilters.return') },
  { value: PropertyType.Driver, text: i18n.tc('mainFilters.driverId') }
];

export const vanPropertyTypes = [
  { value: PropertyType.TrailNumber, text: 'Trailer#' },
  { value: PropertyType.OrderNumber, text: i18n.tc('mainFilters.wo') },
  { value: PropertyType.ReferenceNumber, text: i18n.tc('mainFilters.ref') },
  { value: PropertyType.PONumber, text: i18n.tc('mainFilters.po') },
  { value: PropertyType.MasterBillOfLading, text: i18n.tc('mainFilters.bk') },
  { value: PropertyType.BillTo, text: i18n.tc('mainFilters.billTo') },
  { value: PropertyType.PickupLocation, text: i18n.tc('mainFilters.pickUp') },
  {
    value: PropertyType.DeliveryLocation,
    text: i18n.tc('mainFilters.delivery')
  },
  { value: PropertyType.ReturnLocation, text: i18n.tc('mainFilters.return') },
  { value: PropertyType.Driver, text: i18n.tc('mainFilters.driverId') }
];

export const barePropertyTypes = [
  { value: PropertyType.ChassisNumber, text: 'CHASSIS#' },
  { value: PropertyType.OrderNumber, text: i18n.tc('mainFilters.wo') },
  { value: PropertyType.ReferenceNumber, text: i18n.tc('mainFilters.ref') },
  { value: PropertyType.BillTo, text: i18n.tc('mainFilters.billTo') },
  { value: PropertyType.PickupLocation, text: i18n.tc('mainFilters.pickUp') },
  {
    value: PropertyType.DeliveryLocation,
    text: i18n.tc('mainFilters.delivery')
  },
  { value: PropertyType.ReturnLocation, text: i18n.tc('mainFilters.return') },
  { value: PropertyType.Driver, text: i18n.tc('mainFilters.driverId') }
];

export const mainTypeModels = {
  [MainOrderType.ImportRegular]: allPropertyTypes,
  [MainOrderType.ExportRegular]: exportPropertyTypes,
  [MainOrderType.VanRegular]: vanPropertyTypes,
  [MainOrderType.CyToCy]: allPropertyTypes,
  [MainOrderType.BareChassis]: barePropertyTypes
};
