




















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { WorkOrderListModule } from '@/store/index';
import MainFilters from './MainFilters.vue';
import WoBaseModal from './components/WoBaseModal.vue';
import { mainCategoriesHeaders, mainTableHeaders } from './models/index';
import { TableCategory, TableHeaders } from '@/shared/components/table/models';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { generateRouterClasses } from '@/shared/services/generate-router-classes';
import { PermissionsService } from '@/shared/services/permission/permission.service';

@Component({
  components: {
    MainFilters,
    WoBaseModal,
    TmsTable
  }
})
export default class MainPage extends Vue {
  @Watch('workOrderListModule.page')
  onCurrentPageChange(value) {
    this.currentPage = value;
  }

  @Watch('$route', { immediate: true })
  onRouterChange() {
    this.classes = generateRouterClasses(this.$route);
    this.getPermissions();
  }

  workOrderListModule = WorkOrderListModule;
  items: any;
  perPage: any;
  currentPage = 1;
  rows: any;
  columnApi: null;
  defaultColDef = {};
  rowData = null;

  tabIndex = 0;

  selectedWo = null;

  woModalId = 'wo-base-modal';

  classes: string[] = [];

  disabled = false;

  contextMenuOptions = [
    {
      name: this.$i18n.t('contextMenu.detailInfo'),
      slug: 'detail-info-modal',
      index: 0
    },
    {
      name: this.$i18n.t('contextMenu.billingInfo'),
      slug: 'billing-info-modal',
      index: 1
    },
    {
      name: this.$i18n.t('contextMenu.document'),
      slug: 'document-modal',
      index: 2
    },
    {
      name: this.$i18n.t('contextMenu.memo'),
      slug: 'memo-modal',
      index: 3
    },
    {
      name: this.$i18n.t('contextMenu.tracking'),
      slug: 'tracking-modal',
      index: 4
    }
  ];

  get tableData() {
    return this.workOrderListModule.workOrders;
  }

  get headersList(): TableHeaders[] {
    return mainTableHeaders[this.$route.params.type];
  }

  get headersCategoriesList(): TableCategory[] {
    return mainCategoriesHeaders[this.$route.params.type];
  }

  get isLastPage(): boolean {
    return !this.workOrderListModule.meta.lastKey;
  }

  get title(): string {
    switch (this.$route.params.type) {
      case 'IMP-REG':
        return 'import';
      case 'EXP-REG':
        return 'export';
      case 'VAN':
        return 'van';
      case 'CY-TO-CY':
        return 'CY TO CY';
      case 'BARE-CHASSIS':
        return 'BARE CHASSIS';
      default:
        return this.$route.params.type;
    }
  }

  columnModels = null;

  constructor() {
    super();
  }

  getPermissions() {
    let object = '';

    switch (this.$route.params.type) {
      case 'IMP-REG':
        object = 'IMP';
      case 'EXP':
        object = 'EXP';
      case 'VAN':
        object = 'VAN';
      case 'CY-TO-CY':
        object = 'OTHER';
      case 'BARE-CHASSIS':
        object = 'OTHER';
    }

    this.disabled = PermissionsService.can(
      'DSP / MAIN / BC DETAIL',
      object,
      'View'
    );
  }

  onAction(row: { key: string; data: any }) {
    this.onRowClicked(row);
  }

  async onPaginationChange() {
    await this.workOrderListModule.search({ type: this.$route.params.type });
  }

  onRowClicked(row) {
    this.selectedWo = row.data;
    this.tabIndex = 0;
    this.$bvModal.show(this.woModalId);
  }

  rowRightClicked(item: any, index, event) {
    event.preventDefault();
    const contextMenu: any = this.$refs.contextMenu;
    contextMenu.showMenu(event, item);
  }

  onContextMenuOptionSelect(event: {
    item: any;
    option: { name: string; slug: string; index: number };
  }) {
    this.tabIndex = event.option.index;
    this.selectedWo = event.item;
    this.$bvModal.show(this.woModalId);
  }
}
