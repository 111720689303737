























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ScheduleType } from './schedule-filter.model';
import { FilterInput } from '../../../../shared/services/filter/filter.service';
import {
  convertDateFieldToAPIFormatWithoutTime,
  DATE_API_FORMAT
} from './../../../../utils/date.util';

import moment from 'moment-timezone';

@Component({})
export default class ScheduleFilter extends Vue {
  @Prop() toFilter: FilterInput<string>;
  @Prop() fromFilter: FilterInput<string>;

  filterField = ScheduleType.CreatedDate;

  convertDateFieldToAPIFormatWithoutTime = convertDateFieldToAPIFormatWithoutTime;

  maxDate = null;
  minDate = null;

  scheduleTypes = [
    {
      value: ScheduleType.CreatedDate,
      text: this.$i18n.t('mainFilters.createdDate')
    },
    {
      value: ScheduleType.PulloutSchedule,
      text: this.$i18n.t('mainFilters.pullOut')
    },
    {
      value: ScheduleType.DeliverySchedule,
      text: this.$i18n.t('mainFilters.delivery')
    },
    { value: ScheduleType.MTYPU, text: this.$i18n.t('mainFilters.pickUp') },
    {
      value: ScheduleType.ReturnSchedule,
      text: this.$i18n.t('mainFilters.return')
    }
  ];

  created() {
    // this.from = this.fromFilter.search;
    // this.to = this.toFilter.search;
    this.setMinMaxDate();
  }

  fieldChange() {
    // this.setMinMaxDate();

    this.toFilter.field = this.filterField;
    this.fromFilter.field = this.filterField;

    this.isValidDates();
  }

  isValidDates() {
    if (
      !(this.fromFilter.search <= this.toFilter.search) ||
      !(this.toFilter.search >= this.fromFilter.search)
    ) {
      this.$emit('isValidDates', true);
    } else {
      this.$emit('isValidDates', false);
    }
  }

  setMinMaxDate() {
    this.maxDate = moment(this.toFilter.search, DATE_API_FORMAT).format();
    this.minDate = moment(this.fromFilter.search, DATE_API_FORMAT).format();
    // if (this.filterField === ScheduleType.CreatedDate) {

    // } else {
    //   this.maxDate = null;
    //   this.minDate = null;
    // }
  }

  resetType() {
    this.filterField = ScheduleType.CreatedDate;
  }
}
